import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

const ItemMenu = ({ to, children, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ScrollLink 
      to={to} 
      smooth={true} 
      duration={500} 
      className="text-base m-4 text-violet-600 hover:text-white p-2 rounded-md transition-colors duration-200 hover:bg-violet-600 w-full lg:w-auto text-center cursor-pointer"
      onClick={handleClick}
    >
      {children}
    </ScrollLink>
  );
};

export default ItemMenu;

