import React, { useState, useContext } from 'react';
import { LanguageContext } from '../App';

const contactTranslations = {
  en: {
    title: "Let's Build Something Amazing Together",
    subtitle: "Contact us for more information",
    name: "Your Name",
    email: "Your Email",
    message: "Your Message",
    send: "Send Message",
    placeholder: {
      name: "John Doe",
      email: "john@example.com",
      message: "Tell us about your project..."
    }
  },
  pt: {
    title: "Vamos Construir Algo Incrível Juntos",
    subtitle: "Entre em contato para mais informações",
    name: "Seu Nome",
    email: "Seu E-mail",
    message: "Sua Mensagem",
    send: "Enviar Mensagem",
    placeholder: {
      name: "João Silva",
      email: "joao@exemplo.com",
      message: "Conte-nos sobre seu projeto..."
    }
  }
};

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { language } = useContext(LanguageContext);
  const t = contactTranslations[language];

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:contact@tracegg.com?subject=Contact%20from%20Website&body=Name:%20${name}%0AEmail:%20${email}%0AMessage:%20${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <footer id="contact" className="bg-gradient-to-br from-violet-600 to-violet-800 text-white py-16">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">{t.title}</h2>
          <p className="text-lg text-violet-200">{t.subtitle}</p>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-6 bg-white/10 backdrop-blur-sm rounded-2xl p-8 shadow-xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="relative">
              <input
                type="text"
                placeholder={t.placeholder.name}
                className="w-full px-4 py-3 bg-white/20 rounded-lg placeholder-white/60 text-white border border-white/20 focus:border-white focus:ring-2 focus:ring-white/50 outline-none transition duration-200"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label className="absolute -top-6 left-0 text-sm text-violet-200">{t.name}</label>
            </div>
            
            <div className="relative">
              <input
                type="email"
                placeholder={t.placeholder.email}
                className="w-full px-4 py-3 bg-white/20 rounded-lg placeholder-white/60 text-white border border-white/20 focus:border-white focus:ring-2 focus:ring-white/50 outline-none transition duration-200"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label className="absolute -top-6 left-0 text-sm text-violet-200">{t.email}</label>
            </div>
          </div>
          
          <div className="relative">
            <textarea
              placeholder={t.placeholder.message}
              rows="4"
              className="w-full px-4 py-3 bg-white/20 rounded-lg placeholder-white/60 text-white border border-white/20 focus:border-white focus:ring-2 focus:ring-white/50 outline-none transition duration-200 resize-none"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
            <label className="absolute -top-6 left-0 text-sm text-violet-200">{t.message}</label>
          </div>
          
          <div className="text-center">
            <button
              type="submit"
              className="px-8 py-3 bg-white text-violet-700 rounded-lg font-semibold hover:bg-violet-100 transform hover:scale-105 transition duration-200 shadow-lg"
            >
              {t.send}
            </button>
          </div>
        </form>
      </div>
    </footer>
  );
}

export default ContactForm;
