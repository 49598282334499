// Header.js
import React, { useState, useContext } from 'react';
import Navbar from './Nav';
import { LanguageContext } from '../App';
import logoSite from '../imgs/logo_site.png';

export function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useContext(LanguageContext);
  const menuText = language === 'en' ? 'Menu' : 'Menu';
  const closeText = language === 'en' ? 'Close' : 'Fechar';

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? 'hidden' : 'unset';
  };

  const closeNavbar = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <nav className="shadow-md fixed w-full z-50 bg-white">
      <div className="mx-auto w-11/12 flex justify-between flex-col lg:flex-row">
        <div className="flex items-center justify-between p-4">
          <img alt="tracegg logo" src={logoSite} className="h-12"/>
          <button onClick={toggleNavbar} className="lg:hidden text-violet-600 focus:outline-none flex items-center">
            {isOpen ? (
              <>
                <span className="mr-2">{closeText}</span>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </>
            ) : (
              <>
                <span className="mr-2">{menuText}</span>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"/>
                </svg>
              </>
            )}
          </button>
        </div>
        <Navbar closeNavbar={closeNavbar} isOpen={isOpen}/>
      </div>
    </nav>
  );
}
