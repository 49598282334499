// FAQItem.js
import React from 'react';

const FAQItem = ({ question, answer }) => {
  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold">{question}</h3>
      <p className="text-sm">{answer}</p>
    </div>
  );
};

export default FAQItem;
