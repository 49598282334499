import React, { createContext, useContext, useState } from 'react';
import './App.css';
import { Header } from './components/Header';
import FAQItem from './components/FAQItem';
import ComoFuncionaItem from './components/ComoFuncionaItem';
import ContactForm from './components/Contact';

// Create Language Context
export const LanguageContext = createContext();

const translations = {
  en: {
    comoFuncionaItems: [
      {
        title: 'Choose the System Type',
        content: 'Express Software or Custom Software?',
        subContent: 'Which System is ideal for you?',
      },
      {
        title: 'Software Requirements Gathering',
        content: 'Collecting information about software requirements',
        subContent: 'Understanding specific client needs.',
      },
      {
        title: 'Software Development',
        content: 'Software functionality and quality verification',
        subContent: 'Ensuring the software meets standards and expectations',
      },
      {
        title: 'Software Testing and Quality',
        content: 'Software functionality and quality verification',
        subContent: 'Ensuring the software meets standards and expectations',
      },
    ],
    faqItems: [
      {
        question: "What's the difference between Express Software and Custom Software?",
        answer: "Express software is a pre-configured solution with basic functionalities, ideal for simple and low-cost projects. Custom software is developed according to specific client needs, offering greater customization and flexibility."
      },
      {
        question: "How is software requirements information collected?",
        answer: "Software requirements information is collected through client interviews, document analysis, and market research. This step is fundamental to understanding the client's needs and expectations regarding the software to be developed."
      },
      {
        question: "What are the main criteria for ensuring software quality?",
        answer: "The main criteria for ensuring software quality include rigorous functionality, usability, and security testing, as well as adherence to coding standards and development best practices. Continuous client involvement during the process is also fundamental to ensuring the delivery of a high-quality product."
      }
    ],
    header: {
      since: "SINCE 2023 IN THE MARKET, DEVELOPING",
      professional: "PROFESSIONAL",
      websites: "Systems"
    },
    about: {
      title: "About Us",
      description: "TRACE.gg is a Brazilian company based in São José dos Campos, São Paulo state, specialized in developing systems with reliability and comprehensive support. Our team is dedicated to offering high-quality solutions, tailored to meet your business needs. Whether for robust e-commerce platforms, efficient management systems, or innovative applications, we are committed to providing cutting-edge technological solutions and continuous support."
    },
    howItWorks: {
      title: "Web and mobile systems development",
      subtitle: "HOW IT",
      highlight: "WORKS",
    },
    faq: {
      title: "Frequently Asked Questions"
    },
    footer: {
      contact: "Contact",
      socialMedia: "Social Media",
      aboutUs: "About Us",
      aboutText: "TRACE.gg is a systems development agency.",
      rights: "All rights reserved."
    }
  },
  pt: {
    comoFuncionaItems: [
      {
        title: 'Escolha o tipo de Sistema',
        content: 'Software expresso ou Software sob medida?',
        subContent: 'Qual Sistema é o ideal para você?',
      },
      {
        title: 'Levantamento de Requisitos do Software',
        content: 'Coleta de informações sobre requisitos do software',
        subContent: 'Entender as necessidades específicas do cliente.',
      },
      {
        title: 'Desenvolvimento do Software',
        content: 'Verificação da funcionalidade e qualidade do software',
        subContent: 'Garantir que o software atenda aos padrões e expectativas',
      },
      {
        title: 'Testes e Qualidade do Software',
        content: 'Verificação da funcionalidade e qualidade do software',
        subContent: 'Garantir que o software atenda aos padrões e expectativas',
      },
    ],
    faqItems: [
      {
        question: "Qual é a diferença entre um Software expresso e um Software sob medida?",
        answer: "Um software expresso é uma solução pré-configurada com funcionalidades básicas, ideal para projetos simples e de baixo custo. Já um software sob medida é desenvolvido de acordo com as necessidades específicas do cliente, oferecendo maior personalização e flexibilidade."
      },
      {
        question: "Como são coletadas as informações sobre os requisitos do software?",
        answer: "As informações sobre os requisitos do software são coletadas por meio de entrevistas com o cliente, análise de documentos e pesquisas de mercado. Essa etapa é fundamental para entender as necessidades e expectativas do cliente em relação ao software a ser desenvolvido."
      },
      {
        question: "Quais são os principais critérios para garantir a qualidade do software?",
        answer: "Os principais critérios para garantir a qualidade do software incluem testes rigorosos de funcionalidade, usabilidade e segurança, além da adesão a padrões de codificação e boas práticas de desenvolvimento. O envolvimento contínuo do cliente durante o processo também é fundamental para garantir a entrega de um produto de alta qualidade."
      }
    ],
    header: {
      since: "DESDE DE 2023 NO MERCADO, DESENVOLVENDO",
      professional: "PROFISSIONAIS",
      websites: "Sistemas"
    },
    about: {
      title: "Sobre Nós",
      description: "A TRACE.gg é uma empresa brasileira sediada em São José dos Campos, estado de São Paulo, especializada no desenvolvimento de sistemas com confiabilidade e suporte abrangente. Nossa equipe está dedicada a oferecer soluções de alta qualidade, feitas sob medida para atender às suas necessidades empresariais. Seja para plataformas de e-commerce robustas, sistemas de gestão eficientes ou aplicativos inovadores, estamos comprometidos em fornecer soluções tecnológicas de ponta e suporte contínuo."
    },
    howItWorks: {
      title: "Desenvolvimento de sistemas",
      subtitle: "COMO",
      highlight: "FUNCIONA",
    },
    faq: {
      title: "Perguntas Frequentes"
    },
    footer: {
      contact: "Contato",
      socialMedia: "Redes Sociais",
      aboutUs: "Sobre Nós",
      aboutText: "A TRACE.gg é uma agência de desenvolvimento de sistemas.",
      rights: "Todos os direitos reservados."
    }
  }
};

// Language Provider Component
export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(() => {
    // Get saved language from localStorage or default to 'pt'
    return localStorage.getItem('userLanguage') || 'pt';
  });

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('userLanguage', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage: handleLanguageChange, translations }}>
      {children}
    </LanguageContext.Provider>
  );
}

function App() {
  return (
    <LanguageProvider>
      <AppContent />
    </LanguageProvider>
  );
}

function AppContent() {
  const { language, translations } = useContext(LanguageContext);
  const t = translations[language];

  const socialMediaItems = [
    {
      name: 'Twitter',
      url: 'https://twitter.com/tracegg78712',
    },
  ];

  const contatoItem = {
    'email': 'contato@tracegg.com',
    'telefone': '(XX) XXXX-XXXX'
  }

  return (
    <div className="App">
      <article id="quem_somos" className="">
        <Header />
        <div id="quem_somo_2" className='flex w-11/12 min-h-[960px] items-center mx-auto'>
          <div className='grid grid-cols-2 flex text-left'>
            <div className="mx-auto">
              <p className="text-base text-white font-medium ">{t.header.since}</p>
              <strong className="text-4xl md:text-5xl text-white">
                {t.header.websites} <span className="text-purple-700">{t.header.professional}</span>
              </strong>
              <p className="text-base font-medium mt-3 text-white">
                <span className="text-purple-700">{t.header.description}</span>
              </p>
            </div>
          </div>
        </div>
      </article>

      <article id="about" className="py-24 bg-white">
        <div className="container mx-auto lg:w-2/3">
          <div className="text-center">
            <h2 className="text-4xl font-bold mb-8">{t.about.title}</h2>
            <p className="text-lg leading-relaxed text-gray-700">
              {t.about.description}
            </p>
          </div>
        </div>
      </article>

      <article id="como_funciona" className="bg-purple-50 py-12">
        <div className="container mx-auto lg:w-2/3">
          <div className="min-w-full">
            <p className="text-bas text-xl ">{t.howItWorks.title}</p>
            <strong className="text-4xl">
              {t.howItWorks.subtitle} <span className="text-purple-700">{t.howItWorks.highlight}</span>?
            </strong>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 mt-10">
              {t.comoFuncionaItems.map((item, index) => (
                <ComoFuncionaItem key={index} {...item} />
              ))}
            </div>
          </div>
        </div>
      </article>

      <div id="faq" className="bg-purple-50 p-6">
        <div className="container mx-auto w-2/3">
          <h2 className="text-4xl font-bold mb-8">{t.faq.title}</h2>
          {t.faqItems.map((item, index) => (
            <FAQItem key={index} {...item} />
          ))}
        </div>
      </div>

      <ContactForm />

      <footer className="bg-gradient-to-br from-violet-800 to-violet-900 text-white">
        <div className="container mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {/* Contact Info */}
            <div className="space-y-4">
              <h3 className="text-xl font-bold mb-6 border-b-2 border-violet-600 pb-2 inline-block">
                {t.footer.contact}
              </h3>
              <div className="flex items-center space-x-3">
                <svg className="w-5 h-5 text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                </svg>
                <a href={`mailto:${contatoItem.email}`} className="text-violet-200 hover:text-white transition-colors">
                  {contatoItem.email}
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <svg className="w-5 h-5 text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                </svg>
                <span className="text-violet-200">{contatoItem.telefone}</span>
              </div>
            </div>

            {/* Social Media */}
            <div className="space-y-4">
              <h3 className="text-xl font-bold mb-6 border-b-2 border-violet-600 pb-2 inline-block">
                {t.footer.socialMedia}
              </h3>
              <div className="flex flex-col space-y-2">
                {socialMediaItems.map((item, index) => (
                  <a
                    key={index}
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-3 text-violet-200 hover:text-white transition-colors"
                  >
                    <svg className="w-5 h-5 text-violet-400" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
                    </svg>
                    <span>{item.name}</span>
                  </a>
                ))}
              </div>
            </div>

            {/* About Us */}
            <div className="space-y-4">
              <h3 className="text-xl font-bold mb-6 border-b-2 border-violet-600 pb-2 inline-block">
                {t.footer.aboutUs}
              </h3>
              <p className="text-violet-200 leading-relaxed">
                {t.footer.aboutText}
              </p>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-violet-700/50">
          <div className="container mx-auto px-4 py-6 text-center text-violet-200">
            <p>© 2023-{new Date().getFullYear()} TRACEgg. {t.footer.rights}</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
