// Nav.js
import React, { useContext } from 'react';
import ItemMenu from './ItemMenu';
import { LanguageContext } from '../App';

const menuTranslations = {
  en: {
    who_we_are: "Who We Are?",
    how_it_works: "How It Works?",
    faq: "FAQ",
    contact: "Contact",
    about: "About Us",
    menu: "Menu",
    close: "Close"
  },
  pt: {
    who_we_are: "Quem Somos?",
    how_it_works: "Como Funciona?",
    faq: "Perguntas Frequentes",
    contact: "Contato",
    about: "Sobre Nós",
    menu: "Menu",
    close: "Fechar"
  }
};

const Navbar = ({ closeNavbar, isOpen }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const t = menuTranslations[language];

  return (
    <div className={`${isOpen ? 'flex flex-col items-center fixed inset-0 pt-20 bg-white' : 'hidden lg:flex lg:items-center'}`}>
      <div className={`${isOpen ? 'flex flex-col items-center space-y-4' : 'flex items-center'}`}>
        <ItemMenu to="quem_somos" onClick={closeNavbar}>{t.who_we_are}</ItemMenu>
        <ItemMenu to="como_funciona" onClick={closeNavbar}>{t.how_it_works}</ItemMenu>
        <ItemMenu to="about" onClick={closeNavbar}>{t.about}</ItemMenu>
        <ItemMenu to="faq" onClick={closeNavbar}>{t.faq}</ItemMenu>
        <ItemMenu to="contact" onClick={closeNavbar}>{t.contact}</ItemMenu>
        <select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          className="text-base m-4 text-violet-600 p-2 rounded-md cursor-pointer bg-transparent hover:bg-violet-600 hover:text-white focus:outline-none"
        >
          <option value="pt" className="text-violet-600 bg-white hover:text-violet-600">🇧🇷 PT</option>
          <option value="en" className="text-violet-600 bg-white hover:text-violet-600">🇺🇸 EN</option>
        </select>
      </div>
    </div>
  );
};

export default Navbar;
