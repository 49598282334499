// ComoFuncionaItem.js
import React from 'react';
import PropTypes from 'prop-types';

function ComoFuncionaItem({ title, content, subContent }) {
  return (
    <div className="max-w-sm overflow-hidden shadow-lg mx-auto mb-8 border-2 border-purple-700 rounded-tl-lg rounded-br-lg lg:mb-0 lg:mx-0">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-700 text-base">{content}</p>
        {subContent && <p className="text-gray-700 text-base mt-2">{subContent}</p>}
      </div>
    </div>
  );
}

ComoFuncionaItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  subContent: PropTypes.string,
};

export default ComoFuncionaItem;
